// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBTQAuwYu-llszsH7dN8Wj0Ag--IZfyXuk",
  authDomain: "no-login-044.firebaseapp.com",
  projectId: "no-login-044",
  storageBucket: "no-login-044.appspot.com",
  messagingSenderId: "396141667599",
  appId: "1:396141667599:web:8da520ba56620ee48e0a7a",
  measurementId: "G-Y1C9PFJXS1",
  databaseURL: "https://no-login-044-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { firestore, storage };
