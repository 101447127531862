// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import TermsAndConditions from './components/TermsAndConditions';
import Text from './components/Text';
import TextEditor from './components/TextEditor';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/:domainName" element={<TextEditor />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
