import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import bcrypt from 'bcryptjs';
import { firestore, storage } from '../firebaseConfig';
import './TextEditor.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Navbar from './Navbar';
import { deleteObject } from 'firebase/storage';

export default function TextEditor() {
  const { domainName } = useParams();
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]); // Now stores an array of files
  const [fileURL, setFileURL] = useState('');
  const [showFileSection, setShowFileSection] = useState(true);
  const [isProtected, setIsProtected] = useState(false); // New state for password protection
  const [inputPassword, setInputPassword] = useState(''); // New state for password input
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const fileInputRef = useRef(); // Ref to trigger file dialog
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(firestore, 'documents', domainName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setIsProtected(data.passwordSet); // Check if password is set
          setFiles(data.files || []);
          if (!data.passwordSet) {
            setText(data.text || ''); // Only set text if no password
          } else {
            console.log('No such document!');
          }
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchData();
  }, [domainName, navigate]);

  const handlePasswordSubmit = async () => {
    try {
      const docRef = doc(firestore, 'documents', domainName);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        // Verify password using bcrypt
        const isPasswordCorrect = await bcrypt.compare(inputPassword, data.password);
        if (isPasswordCorrect) {
          setText(data.text || '');
          setIsAuthenticated(true);
          toast.success('Password verified!');
        } else {
          toast.error('Incorrect password.');
        }
      }
    } catch (error) {
      console.error('Error verifying password:', error);
      toast.error('Error verifying password.');
    }
  };

  const handleSave = async () => {
    try {
      const docRef = doc(firestore, 'documents', domainName);
      const docSnap = await getDoc(docRef);
      const existingData = docSnap.exists() ? docSnap.data() : {};

      const newData = {
        ...existingData,
        text: text,
        files: files, // Save files
      };

      await setDoc(docRef, newData);
      toast.success('Document successfully saved!');
    } catch (error) {
      console.error('Error writing document:', error);
      toast.error('Error saving document.');
    }
  };

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileRef = ref(storage, `files/${domainName}/${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      setFiles([...files, { name: file.name, url }]); // Add file to files array
      toast.success('File uploaded successfully!');
    }
  };

  const handleDeleteFile = async (file) => {
    try {
      // Reference to the file in Firebase Storage
      const fileRef = ref(storage, `files/${domainName}/${file.name}`);

      // Delete the file from Firebase Storage
      await deleteObject(fileRef);

      // Update Firestore by removing the file from the files array
      const updatedFiles = files.filter((f) => f.name !== file.name);
      setFiles(updatedFiles); // Update the state immediately for UI responsiveness

      const docRef = doc(firestore, 'documents', domainName);
      const existingData = (await getDoc(docRef)).data();
      await setDoc(docRef, { ...existingData, files: updatedFiles });

      toast.success('File deleted successfully!');
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error('Error deleting file.');
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click(); // Trigger the file dialog
  };

  const toggleFileSection = () => {
    setShowFileSection(!showFileSection); // Toggle file section visibility
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text); // Copy text to clipboard
    toast.success('Text copied to clipboard!');
  };

  return (
    <div className="editor-container">
      <Navbar />
      <ToastContainer />
      {!isAuthenticated && isProtected ? (
        <div className="password-prompt">
          <div className="password-container">
            <h2 className="password-title">🔒 Secure Document</h2>
            <p className="password-subtitle">
              This document is password-protected. Please enter your password to unlock.
            </p>
            <div className="password-input-group">
              <div className="input-wrapper">
                <span className="input-icon">🔑</span>
                <input
                  type="password"
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                  placeholder="Enter your password"
                  className="password-input"
                />
              </div>
              <button onClick={handlePasswordSubmit} className="submit-password-button">
                Unlock
              </button>
            </div>
            <p className="password-error">Incorrect password? Please try again.</p>
          </div>
        </div>


      ) : (
        <div className="split-screen">
          <div className="text-section">
            <textarea
              value={text}
              placeholder="Enter text and hit save button"
              onChange={(e) => setText(e.target.value)}
              className="text-area"
            />
            <div className="copy-button" onClick={copyToClipboard}>
              <ContentCopyIcon /> Copy
            </div>
            <button onClick={toggleFileSection} className="toggle-button">
              {showFileSection ? (
                <ArrowDropDownIcon fontSize="large" /> /* Show Down Icon */
              ) : (
                <ArrowDropUpIcon fontSize="large" /> /* Show Up Icon */
              )}
            </button>

          </div>

          {showFileSection &&
            <div className="upload-section">
              <div className="file-upload">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <button onClick={triggerFileUpload} className="upload-button">
                  Upload File
                </button>
                {files.length > 0 && (
                  <div className="uploaded-files">
                    {files.map((file, index) => (
                      <div key={index} className="file-item">
                        <div className="file-icon">📄</div>
                        <div className="file-details">
                          <a href={file.url} target="_blank" rel="noopener noreferrer" className="file-name">
                            {file.name}
                          </a>
                          <div className="file-actions">
                            <button
                              className="download-button"
                              onClick={(e) => {
                                e.preventDefault();
                                const link = document.createElement('a');
                                link.href = file.url;
                                link.download = file.name;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                            >
                              Download
                            </button>
                            <button
                              className="delete-button"
                              onClick={() => handleDeleteFile(file)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          }

          <button onClick={handleSave} className="save-button">Save</button>
        </div>
      )}
    </div>
  );
}
