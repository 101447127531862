import { ArrowUpRight } from 'lucide-react';
import React, { useEffect } from 'react';
import './About.css';
import Navbar from './Navbar';

const About = () => {
  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          entry.target.classList.remove('animate');
        }
      });
    };

    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const animatedElements = document.querySelectorAll('.fade-up');

    animatedElements.forEach((el) => observer.observe(el));

    return () => {
      animatedElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="about-container">
      <Navbar />
      <div className="hero-section">
        <h1 className="hero-title fade-up custom-font">
          About <span className="purple">No</span><span className="orange">login</span>
        </h1>
        <p className="hero-subtitle fade-up">Simplifying Text, Document Sharing for Everyone</p>
      </div>

      <div className="content-section">
        <div className="mission-block fade-up">
          <h2 className="custom-font">Our Mission</h2>
          <p>At Nologin, we believe in making text and document sharing simple and efficient. Our platform is designed to provide fast, secure, and hassle-free sharing, all without the need for login credentials.</p>
        </div>

        <div className="features-grid">
          <div className="feature-card fade-up" style={{ "--delay": "0.1s" }}>
            <div className="feature-icon">
              <svg viewBox="0 0 24 24" className="icon">
                <path fill="currentColor" d="M13 2.05v3.03c3.39.49 6 3.39 6 6.92 0 .9-.18 1.75-.5 2.54l2.54 1.27c.56-1.24.88-2.62.88-4.05 0-5.18-3.95-9.45-8.92-9.71zM12 19c-3.87 0-7-3.13-7-7 0-3.53 2.61-6.43 6-6.92V2.05C5.94 2.31 2 6.58 2 11.75c0 5.52 4.47 10 9.99 10 3.31 0 6.24-1.61 8.06-4.09l-2.6-1.53C16.17 17.98 14.21 19 12 19z" />
              </svg>
            </div>
            <h3 className="custom-font">Hassle-Free Sharing</h3>
            <p>No more carrying pen drives or managing multiple logins. Share your content instantly across any device.</p>
          </div>
          <div className="feature-card fade-up" style={{ "--delay": "0.2s" }}>
            <div className="feature-icon">
              <svg viewBox="0 0 24 24" className="icon">
                <path fill="currentColor" d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
              </svg>
            </div>
            <h3 className="custom-font">Secure Access</h3>
            <p>Custom link expiry and read-only access ensure your content remains private and secure.</p>
          </div>
          <div className="feature-card fade-up" style={{ "--delay": "0.3s" }}>
            <div className="feature-icon">
              <svg viewBox="0 0 24 24" className="icon">
                <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
              </svg>
            </div>
            <h3 className="custom-font">Universal Access</h3>
            <p>Whether you're a student, educator, or professional, Nologin streamlines file-sharing across all platforms.</p>
          </div>
          <div className="feature-card fade-up" style={{ "--delay": "0.4s" }}>
            <div className="feature-icon">
              <svg viewBox="0 0 24 24" className="icon">
                <path fill="currentColor" d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
              </svg>
            </div>
            <h3 className="custom-font">Time Efficient</h3>
            <p>Quick sharing without the need for account creation or authentication processes.</p>
          </div>
        </div>

        <footer className="contact-section fade-up">
          <div className="footer-content">
            <div className="footer-left">
              <h2 className="custom-font">Connect with Us</h2>
              <p>Join our community and stay updated with the latest features and improvements.</p>
            </div>
            <div className="social-links">
              <a href="https://www.linkedin.com/company/nologin-in" target="_blank" rel="noopener noreferrer" className="social-link">
                LinkedIn
                <ArrowUpRight className="link-icon" />
              </a>
              <a href="https://www.instagram.com/nologin.in" target="_blank" rel="noopener noreferrer" className="social-link">
                Instagram
                <ArrowUpRight className="link-icon" />
              </a>
              <a href="mailto:nologin044@gmail.com" className="social-link">
                Email
                <ArrowUpRight className="link-icon" />
              </a>
            </div>
          </div>
          <div className="footer-bottom">
            <p>© 2024 Nologin. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default About;