import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Text() {
  const [file, setFile] = useState('');
  const [img, setImg] = useState('');
  const [text,setText]=useState('');
  const { domainName } = useParams();

  const loadDocument = async() => {
    try{
      const res = await axios.post('http://localhost:8000/api/getDocument', {url: domainName});
      if(res.status === 200){
        if(res.data.password == ""){
          setText(res.data.content);
        }
        else{
          setText(res.data.content);
        }
      }
    }
    catch(err){
      console.log(`Error: ${err} `);
    }
  
  }

  useEffect(() => {
    loadDocument();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleImg = (e) => {
    console.log(e.target.files);
    setImg(URL.createObjectURL(e.target.files[0]));
  };
  const handleText=(e)=>{
    console.log(e.target.value);
    setText(e.target.value);
  }

  const save = async () => {
    try {
        const res = await axios.post('http://localhost:8000/api/postDocument', { url: domainName, content: text });
        if(res.status === 200){
          alert("Saved successfully");
        }
        else{
          alert(`Error occured: ${res.message}`);
        }
    } catch (err) {
        alert(`Error occured: ${err}`);
        console.error(err.response.data.message || err.message);
    }
}


  return (
    <>
      <h1>page 2</h1>
      <div>
        <h2>Upload File</h2>
        <input type="file" onChange={handleFileChange} />
        {file && <div>{file.name} - {file.type}</div>}
      </div>
      <div>
        <h2>Add Image</h2>
        <input type="file" onChange={handleImg} />
        {img && <img src={img} alt="Uploaded" />}
      </div>
      <div>
        <h2>Upload text</h2>
        <input type="textarea" onChange={handleText} value={text} />
      </div>
      <div>
        <button onClick={save}>Save</button>
      </div>
    </>
  );
}

export default Text;
