import React, { useEffect } from "react";
import Navbar from "./Navbar";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
        } else {
          entry.target.classList.remove("animate");
        }
      });
    };

    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px 0px -10% 0px'
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    const animatedElements = document.querySelectorAll(".fade-up");

    animatedElements.forEach((el) => observer.observe(el));

    return () => {
      animatedElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="terms-container">
      <Navbar />
      <div className="terms-hero">
        <h1 className="terms-title fade-up custom-font">
          Terms & <span className="purple">Con</span>
          <span className="orange">ditions</span> - Nologin
        </h1>
        <p className="terms-subtitle fade-up">
          Understanding Your Rights and Responsibilities on Nologin
        </p>
      </div>

      <div className="terms-content">
        <div className="terms-intro fade-up">
          <h2 className="custom-font">Legal Agreement</h2>
          <p>
            Welcome to Nologin, a platform designed for seamless and secure
            sharing of text and documents without requiring login credentials.
            Before using our services, please carefully read the terms and
            conditions outlined below.
          </p>
        </div>

        <div className="terms-sections">
          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"
                />
              </svg>
              <h3 className="section-title custom-font">Acceptance of Terms</h3>
            </div>
            <p className="section-content">
              By using Nologin, you agree to comply with these terms and
              conditions, as well as our Privacy Policy. If you do not agree
              with any part of these terms, you should not use our platform.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
                />
              </svg>
              <h3 className="section-title custom-font">User Responsibility</h3>
            </div>
            <p className="section-content">
              Nologin provides a platform for sharing content, but we do not
              control or monitor what is shared through our service. Users are
              solely responsible for any text, files, documents, or other
              content they share or upload through Nologin. This includes
              ensuring that the content complies with all applicable laws and
              regulations.
            </p>
            <p className="section-content">
              By using Nologin, you agree not to use the platform for:
              <br />
              - Sharing illegal, harmful, or malicious content.
              <br />
              - Violating the privacy or intellectual property rights of
              others.
              <br />
              - Distributing offensive, defamatory, or inappropriate content.
              <br />
              - Spreading malware, spam, or any malicious activity.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                />
              </svg>
              <h3 className="section-title custom-font">Content Disclaimer</h3>
            </div>
            <p className="section-content">
              Nologin is not liable for the content uploaded or shared by its
              users. We do not guarantee the accuracy, reliability, or legality
              of any content shared through the platform. You access and share
              content at your own risk, and you are responsible for ensuring
              that your use of Nologin complies with applicable laws. We reserve
              the right to remove any content that violates these terms, and we
              may suspend or terminate accounts involved in sharing such
              content. However, we are under no obligation to monitor, review,
              or censor content shared on Nologin.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
                />
              </svg>
              <h3 className="section-title custom-font">
                Security and Privacy
              </h3>
            </div>
            <p className="section-content">
              While Nologin uses industry-standard measures to protect user
              data, we do not guarantee complete security of the files and
              information shared on the platform. You are responsible for
              ensuring the security of the content you upload, including using
              features like password protection, read-only modes, and setting
              link expirations. Nologin will not be held responsible for any
              data breaches, leaks, or unauthorized access to files shared
              through the platform. Users are encouraged to use the security
              features available to safeguard sensitive or private content.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                />
              </svg>
              <h3 className="section-title custom-font">
                Limitation of Liability
              </h3>
            </div>
            <p className="section-content">
              Under no circumstances will Nologin, its founders, developers, or
              affiliates be held liable for any direct, indirect, incidental, or
              consequential damages arising from:
              <br />- The use or inability to use our platform.
              <br />- The security, privacy, or protection of content shared
              through Nologin.
              <br />- Any loss of data or unauthorized access to user content.
              <br />- Content shared by users that may cause harm, offense, or
              violate legal rights.
              <br />
              <br />
              Nologin is provided "as is" without any warranties, express or
              implied. We do not guarantee the platform will always be available
              or free of errors or interruptions.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
                />
              </svg>
              <h3 className="section-title custom-font">Third-Party Links</h3>
            </div>
            <p className="section-content">
              Nologin may contain links to third-party websites. We are not
              responsible for the content, privacy policies, or practices of any
              third-party sites. Once you leave our platform, you are subject to
              the terms and policies of the respective third-party site.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
                />
              </svg>
              <h3 className="section-title custom-font">
                Modifications to the Terms
              </h3>
            </div>
            <p className="section-content">
              Nologin reserves the right to update or modify these terms and
              conditions at any time without prior notice. By continuing to use
              the platform, you agree to be bound by the updated terms. We
              encourage users to periodically review these terms to stay
              informed.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
                />
              </svg>
              <h3 className="section-title custom-font">Governing Law</h3>
            </div>
            <p className="section-content">
              These terms and conditions are governed by and construed in
              accordance with the laws of India. Any disputes arising in
              relation to these terms will be subject to the exclusive
              jurisdiction of the courts in Bangalore, Karnataka.
            </p>
          </div>

          <div className="terms-section fade-up">
            <div className="section-header">
              <svg viewBox="0 0 24 24" className="section-icon">
                <path
                  fill="currentColor"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v6h2V7zm0 8h-2v2h2v-2z"
                />
              </svg>
              <h3 className="section-title custom-font">Consent</h3>
            </div>
            <p className="section-content">
              By using Nologin, you consent to our terms and conditions and
              agree to abide by them.
            </p>
          </div>
        </div>
      </div>

      <footer className="terms-footer fade-up">
        <div className="footer-section">
          <h2 className="custom-font">Affiliate Disclosure</h2>
          <p>
            Nologin may participate in affiliate marketing programs. Any
            purchases made through affiliate links on the platform may result in
            a commission for Nologin at no extra cost to you.
          </p>
        </div>

        <div className="footer-section">
          <h2 className="custom-font">Questions About Our Terms?</h2>
          <p>
            If you need clarification or have concerns about our terms and
            conditions, please contact our support team at support@nologin.com
          </p>
        </div>

        <div className="terms-copyright">
          <p>© 2024 Nologin. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
